import { useState, useEffect } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'

import { commonHelper } from 'helpers'
import { useGetObjective } from 'services/clients/clients.service'

import { commonUtils, eventManager } from 'utils'
import { addEditObjectiveMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditObjectivePayload } from 'services/shared/mutation.payload'
import { TCustomCheckboxValue } from 'components/CustomCheckbox/CustomCheckbox.types'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useObjectiveModal = () => {
  const [form] = Form.useForm()
  const addEditObjective = useCustomMutation<AddEditObjectivePayload>({ mutation: addEditObjectiveMutation, refetchQueries: ['getObjectives'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [modalState, setModalState] = useState(initialState)
  const objectiveId = modalState?.data?._id
  const { objective, loading: initialDataLoading } = useGetObjective({ idNumber, objectiveId, skip: !modalState.isOpen || !modalState.data.isEdit })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    needsToRaiseCapital: objective.needsToRaiseCapital,
    purposeOfRaisingCapital: objective.purposeOfRaisingCapital,
    requiredAmount: objective.requiredAmount,
    fundsRequiredIn: objective.fundsRequiredIn,
    description: objective.description,
  })

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(objective)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleCustomTextAreaChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    eventManager.addListener('objective-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeObjectiveModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditObjective({
      idNumber,
      objectiveToEdit: objectiveId,
      values: {
        ...formData,
        requiredAmount: Number(formData.requiredAmount),
      },
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomCheckboxChange,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
  }
}

export default useObjectiveModal
