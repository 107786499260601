import { useState, useEffect } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'

import { eventManager } from 'utils'
import config from 'config'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useFileUploadModal = () => {
  const [form] = Form.useForm()
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [modalState, setModalState] = useState(initialState)
  const [description, setDescription] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState<string>('')

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setSelectedFile(file)
      setFileName(file.name)
    }
  }

  const isSaveEnabled = selectedFile && description

  const clearModalData = () => {
    setDescription('')
    setFileName('')
    setSelectedFile(null)
    form.setFieldsValue({ description: '' })
  }

  const handleCustomTextAreaChange = (e: any) => {
    setDescription(e.target.value)
  }

  useEffect(() => {
    eventManager.addListener('file-upload-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeFileUploadModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearModalData()
  }

  const handleUpload = async () => {
    try {
      const formData = new FormData()

      // Append the file to the formData only if it is not null
      if (selectedFile) {
        formData.append('file', selectedFile)
      }

      // Append the description to the formData
      formData.append('description', description)

      // Append the idNumber to the formData
      formData.append('idNumber', idNumber.toString())

      const response = await fetch(`${config.API_URL}/clients/upload-file`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        message.error('Upload failed')
        throw new Error('Upload failed')
      }

      message.success('Operation finished successfully')
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleUpload()
      clearModalData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    description,
    handleCustomTextAreaChange,
    fileName,
    handleFileInput,
    isSaveEnabled,
    form,
  }
}

export default useFileUploadModal
