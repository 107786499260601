import { IExpenditureData, ILiabilitiesData } from './Expenditure.interface'

export const expenditureTableColumns = [
  {
    name: 'customer',
    header: 'customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IExpenditureData) => (data.customer ? data.customer : 'N/A'),
  },
  {
    name: 'expenditureType',
    header: 'Type of Expenditure',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IExpenditureData) => (data.expenditureType ? data.expenditureType : 'N/A'),
  },
  {
    name: 'expenditureAmount',
    header: 'Expenditure Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IExpenditureData) => (typeof data.expenditureAmount === 'number' ? `£${data.expenditureAmount}` : 'N/A'),
  },
]

export const liabilitiesTableColumns = [
  {
    name: 'liabilityHolder',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => (data.liabilityHolder ? data.liabilityHolder : 'N/A'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
  {
    name: 'liabilityType',
    header: 'Type of Expenditure',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => (data.liabilityType ? data.liabilityType : 'N/A'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
  {
    name: 'monthlyCost',
    header: 'Expenditure Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ILiabilitiesData) => (typeof data.monthlyCost === 'number' ? `£${data.monthlyCost}` : 'N/A'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
  {
    name: 'willMonthlyCostChange',
    header: 'Will change in the future?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => (data.willMonthlyCostChange ? 'Yes' : 'No'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
  {
    name: 'monthlyCostChangePeriod',
    header: 'When will it change?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => (data.monthlyCostChangePeriod ? data.monthlyCostChangePeriod : 'N/A'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
  {
    name: 'monthlyCostWillChangeTo',
    header: 'To What Amount?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => (data.expenditureWillChangeTo ? `£${data.expenditureWillChangeTo}` : 'N/A'),
    onRender: (cellProps: any) => {
      cellProps.style.color = '#00000061'
    },
  },
]
