import { useState } from 'react'
import Pagination from 'antd/lib/pagination'

import { useWindowDimensions } from 'hooks'
import { TCustomPagination } from './CustomPagination.types'

const CustomPagination = ({ pageSize = 10, count, refetch, style }: TCustomPagination) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 768

  const [activePage, setActivePage] = useState(1)
  const [limit] = useState(pageSize)

  const onPageChange = (page: number) => {
    setActivePage(page)
    const skip = (page - 1) * pageSize
    refetch({
      skip,
      limit,
    })
  }

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <p className="antd-prev-next">Prev</p>
    }
    if (type === 'next') {
      return <p className="antd-prev-next">Next</p>
    }
    return originalElement
  }

  return (
    <Pagination
      size={isMobile ? 'small' : 'default'}
      current={activePage}
      total={Math.ceil(count)}
      showSizeChanger={false}
      pageSize={pageSize}
      onChange={(page) => onPageChange(page)}
      itemRender={itemRender}
      style={style}
    />
  )
}

export default CustomPagination
