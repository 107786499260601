import { useEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd'

import { StyledContainer, StyledLabel, ValuesContainer, StyledRequiredError, StyledRadioGroup, StyledRadio } from './RadioOptions.styles'
import { RadioOptionsProps } from './RadioOptions.types'

const RadioOptions = ({ label, onChange, options, name, initialValue, required = false }: RadioOptionsProps) => {
  const [error, setError] = useState(false)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    onChange({ name, value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    setError(false)
  }

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <ValuesContainer>
        <StyledRadioGroup onChange={handleChange} value={value}>
          {options.map((option: any) => (
            <StyledRadio key={option.value} value={option.value}>
              {option.label}
            </StyledRadio>
          ))}
        </StyledRadioGroup>
      </ValuesContainer>
      {error && <StyledRequiredError>Required</StyledRequiredError>}
    </StyledContainer>
  )
}

export default RadioOptions
