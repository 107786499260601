import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { commonUtils } from 'utils'

import { ISuccessfulQuote } from 'interfaces/quoteResult.interface'
import { Quote } from 'pages/QuoteResult/Tabs/LifeTimeMortgagesTab/LifeTimeMortgagesTab.interface'

const initialFilterData = {
  providers: [],
  erc: [],
  initialSumRange: {
    min: 0,
    max: 0,
  },
  interestRate: {
    min: 0,
    max: 0,
  },
  rateType: [],
  earlyRepaymentChargesPeriod: 0,
  downsizingProtection: '',
  interestType: '',
}

export const getKeys = (data: any) => {
  const keys = []
  if (data?.cashback_ind?.[0] === 'true') keys.push('CB')
  if (data?.cashback_considered_ind?.[0] === 'true') keys.push('CBD')
  if (data?.optional_repayments_ind?.[0] === 'true') keys.push('FOR')
  if (data?.free_valuations_ind?.[0] === 'true') keys.push('FV')
  if (data?.special_deal?.[0] === 'Club') keys.push('Special')
  if (data?.guaranteed_quote_ind?.[0] === 'Club') keys.push('GQ')
  if (data?.kfi_available?.[0] === 'Club') keys.push('KFI')

  return keys
}

const useLifeTimeMortgagesTab = () => {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [dataSource, setDataSource] = useState<Quote[]>([])
  const successFullQuote: ISuccessfulQuote[] = JSON.parse(localStorage.getItem('quoteResult') || '{}').successFullQuotes
  const [filteredData, setFilteredData] = useState<Quote[]>([])
  const [open, setOpen] = useState(false)
  const [areFilterApplied, setAreFilterApplied] = useState(false)

  const [filterData, setFilterData] = useState(initialFilterData)

  useEffect(() => {
    const dataSource1 = successFullQuote?.map((item: ISuccessfulQuote) => {
      const keys = getKeys(item.provider_success?.[0].quote_results?.[0])
      const providerName = item.provider_success[0].provider_product_data[0].provider_name[0]
      const avatar = commonUtils.getAvatarFromProviderName(providerName)

      const transactionNumber = item.$['perqresp:service_provider_reference']

      const splittedQuoteResponseIdentifier = item.$['perqresp:quote_response_identifier'].split('_')
      const interestType = splittedQuoteResponseIdentifier[splittedQuoteResponseIdentifier.length - 1]
      let modifiedInterestType = ''
      if (interestType === 'Lifetime') modifiedInterestType = 'Roll up'
      if (interestType === 'LifetimeAndInterest') modifiedInterestType = 'Roll up/Interest'
      if (interestType === 'Interest') modifiedInterestType = 'Interest only'

      return {
        id: item.$['perqresp:quote_response_identifier'],
        provider: providerName,
        product: item.provider_success[0].provider_product_data[0].provider_variant_name[0],
        initialSum: item.provider_success[0].quote_results[0].payments[0].initial_only[0].initial_amount[0]._,
        netLoanAmount: item.provider_success[0].quote_results[0].net_loan_amount[0]._,
        mer: item.provider_success[0].quote_results[0].interest_rates[0].mer[0],
        aer: item.provider_success[0].quote_results[0].interest_rates[0].aer[0],
        costPerPound: item.provider_success[0].quote_results[0].cost_per_pound?.[0]._,
        totalCostOfLoan: item.provider_success[0].quote_results[0].total_cost_of_loan?.[0]._,
        rateType: item.provider_success[0].quote_results[0].interest_rates[0].type[0],
        notes: item.provider_success[0].quote_results[0].product_notes?.[0].product_note || [],
        transactionNumber: transactionNumber,
        avatar: avatar,
        keys,
        commission: item.provider_success[0].quote_results[0].commission_paid?.[0]._,
        earlyRepaymentChargesPeriod: Number(item.provider_success[0].quote_results[0].early_repayment_charge[0].years || 0),
        erc: item.provider_success[0].quote_results[0].early_repayment_charge[0].type[0],
        downsizingProtection: item.provider_success[0].quote_results[0].downsizing_protection_ind[0] === 'true' ? 'Yes' : 'No',
        interestType: modifiedInterestType,
      }
    })
    setDataSource(dataSource1)
    setFilteredData(dataSource1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateFilterOptions = () => {
    const providerNames = dataSource?.map((item) => item.provider)
    const providerCount = providerNames.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {} as Record<string, number>)

    const providerOptions = Object.entries(providerCount).map(([providerName, count]) => ({
      label: `${providerName} (${count})`,
      value: providerName,
    }))

    const downsizingOptions = dataSource?.map((item) => item.downsizingProtection)
    const downsizingProtectionOptionCount = downsizingOptions.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {} as Record<string, number>)

    const downsizingProtectionOptions = Object.entries(downsizingProtectionOptionCount).map(([downsizingOption, count]) => ({
      label: `${downsizingOption} (${count})`,
      value: downsizingOption,
    }))

    const interestTypes = dataSource?.map((item) => item.interestType)
    const interestTypeCount = interestTypes.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {} as Record<string, number>)

    const interestTypeOptions = Object.entries(interestTypeCount).map(([interestType, count]) => ({
      label: `${interestType} (${count})`,
      value: interestType,
    }))

    const earlyRepaymentCharges = dataSource?.map((item) => item.erc)
    const ercCount = earlyRepaymentCharges.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {} as Record<string, number>)

    const ercOptions = Object.entries(ercCount).map(([ercType, count]) => ({
      label: `${ercType} (${count})`,
      value: ercType,
    }))

    // Get all initialSum and aer values and convert them to numbers
    const initialSums = dataSource?.map((item) => Number(item.initialSum))
    const interestRates = dataSource?.map((item) => Number(item.aer))

    // Find the lowest and highest initialSum
    const lowestInitialSum = Math.min(...initialSums)
    const highestInitialSum = Math.max(...initialSums)

    // Find the lowest and highest interestRate
    const lowestInterestRate = Math.min(...interestRates)
    const highestInterestRate = Math.max(...interestRates)

    const rateTypes = dataSource?.map((item) => item.rateType)

    // Create a unique set of rateTypes
    const uniqueRateTypes = Array.from(new Set(rateTypes))

    // Create rateType options
    const rateTypeOptions = uniqueRateTypes.map((rateType) => ({
      label: rateType,
      value: rateType,
    }))

    // Get all earlyRepaymentChargesPeriod values and convert them to numbers
    const earlyRepaymentChargesPeriods = dataSource?.map((item) => Number(item.earlyRepaymentChargesPeriod))

    // Filter out 0 values
    const filteredEarlyRepaymentChargesPeriods = earlyRepaymentChargesPeriods.filter((item) => item !== 0)

    // Find the lowest and highest earlyRepaymentChargesPeriod
    const lowestEarlyRepaymentChargesPeriod = Math.min(...filteredEarlyRepaymentChargesPeriods)
    const highestEarlyRepaymentChargesPeriod = Math.max(...filteredEarlyRepaymentChargesPeriods)

    return {
      providerOptions,
      downsizingProtectionOptions,
      interestTypeOptions,
      ercOptions,
      initialSumRange: {
        min: lowestInitialSum,
        max: highestInitialSum,
      },
      interestRateRange: {
        min: lowestInterestRate,
        max: highestInterestRate,
      },
      rateTypeOptions,
      earlyRepaymentChargesPeriodRange: {
        min: lowestEarlyRepaymentChargesPeriod,
        max: highestEarlyRepaymentChargesPeriod,
      },
    }
  }

  const onRowClick = useCallback((rowProps: any, event: any) => {
    const quoteDetailId = rowProps.data.id
    navigate(`/quote-details?id=${quoteDetailId}&idNumber=${idNumber}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = (filterData: any) => {
    let dataToFilter = dataSource

    if (filterData.providers.length > 0) {
      dataToFilter = dataToFilter.filter((item) => filterData.providers.includes(item.provider))
      setAreFilterApplied(true)
    }

    if (filterData.erc.length > 0) {
      dataToFilter = dataToFilter.filter((item) => filterData.erc.includes(item.erc))
      setAreFilterApplied(true)
    }

    if (filterData.initialSumRange.min > 0) {
      dataToFilter = dataToFilter.filter((item) => item.initialSum >= filterData.initialSumRange.min)
      setAreFilterApplied(true)
    }

    if (filterData.initialSumRange.max > 0) {
      dataToFilter = dataToFilter.filter((item) => item.initialSum <= filterData.initialSumRange.max)
      setAreFilterApplied(true)
    }

    if (filterData.interestRate.min > 0) {
      dataToFilter = dataToFilter.filter((item) => item.aer >= filterData.interestRate.min)
      setAreFilterApplied(true)
    }

    if (filterData.interestRate.max > 0) {
      dataToFilter = dataToFilter.filter((item) => item.aer <= filterData.interestRate.max)
      setAreFilterApplied(true)
    }

    if (filterData.rateType.length > 0) {
      dataToFilter = dataToFilter.filter((item) => filterData.rateType.includes(item.rateType))
      setAreFilterApplied(true)
    }

    if (filterData.earlyRepaymentChargesPeriod > 0) {
      dataToFilter = dataToFilter.filter((item) => item.earlyRepaymentChargesPeriod === filterData.earlyRepaymentChargesPeriod)
      setAreFilterApplied(true)
    }

    if (filterData.downsizingProtection?.length > 0) {
      dataToFilter = dataToFilter.filter((item) => filterData.downsizingProtection.includes(item.downsizingProtection))
      setAreFilterApplied(true)
    }

    if (filterData.interestType?.length > 0) {
      dataToFilter = dataToFilter.filter((item) => filterData.interestType.includes(item.interestType))
      setAreFilterApplied(true)
    }

    setFilteredData(dataToFilter)
    setOpen(false)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const clearFilters = () => {
    setAreFilterApplied(false)
    setFilterData(initialFilterData)
    handleFilter(initialFilterData)
  }

  return {
    areFilterApplied,
    clearFilters,
    filteredData,
    open,
    setOpen,
    filterData,
    setFilterData,
    onRowClick,
    closeDrawer,
    handleFilter,
    generateFilterOptions,
  }
}

export default useLifeTimeMortgagesTab
