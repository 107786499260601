import Form from 'antd/lib/form/Form'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import CustomTextArea from 'components/CustomTextArea'
import CustomFormItem from 'components/CustomFormItem'
import EditDeleteButton from 'components/EditDeleteButton'
import useUserNotes from 'hooks/user/useUserNotes'

import {
  StyledAddButton,
  StyledCancelButton,
  StyledCheckbox,
  StyledCheckboxMsg,
  StyledEmptyNotesText,
  StyledEmptyNotesWrapper,
  StyledFieldWrapper,
  StyledHeaderActionsWrapper,
  StyledItemContainer,
  StyledNoteWrapper,
  StyledRows,
  StyledSaveButton,
  StyledActions,
  StyledWrapper,
  StylesFieldLabel,
  StyledMinContainer,
  StyledContainer,
} from './NotesContent.styles'
import { INote } from './NotesContent.interface'

const NotesContent = () => {
  const {
    notes,
    addNote,
    addingNewNote,
    displayEmptyNotesUI,
    selectAllNotes,
    selectAllNotesChange,
    selectNote,
    newNote,
    cancelNewItemInsert,
    handleCustomTextAreaChange,
    handleCustomFormItemChange,
    saveNote,
    handleDeleteModal,
    handleEditClick,
    handleCustomTextAreaChangeForEdit,
    handleCustomFormItemChangeForEdit,
    cancelEdit,
  } = useUserNotes()

  if (displayEmptyNotesUI) {
    return (
      <StyledContainer>
        <StyledMinContainer>
          <StyledEmptyNotesWrapper>
            <StyledEmptyNotesText>You don't have any note on this user yet</StyledEmptyNotesText>
            <StyledAddButton onClick={addNote}>Add New</StyledAddButton>
          </StyledEmptyNotesWrapper>
        </StyledMinContainer>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      <StyledMinContainer>
        <StyledWrapper>
          <StyledActions>
            <StyledItemContainer>
              <StyledCheckbox onChange={selectAllNotesChange} checked={selectAllNotes} />
              <StyledCheckboxMsg>Select all</StyledCheckboxMsg>
            </StyledItemContainer>
            <StyledHeaderActionsWrapper>
              {selectAllNotes && <EditDeleteButton onClick={() => handleDeleteModal('')} type="delete" value="Delete All" />}
              <StyledAddButton disabled={addingNewNote} onClick={addNote}>
                Add New
              </StyledAddButton>
            </StyledHeaderActionsWrapper>
          </StyledActions>
          {addingNewNote && (
            <StyledNoteWrapper isAddNewNote={true}>
              <StyledRows>
                <CustomTextArea
                  label="Note"
                  placeholder="Note"
                  value={newNote.description}
                  name="description"
                  onChange={handleCustomTextAreaChange}
                  rows={7}
                  wrapperStyles={{ marginTop: 0 }}
                />
                <Form
                  layout="vertical"
                  size="large"
                  name="basic"
                  style={{
                    alignSelf: 'flex-start',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                  }}
                  initialValues={{
                    createdAt: newNote.createdAt,
                    title: newNote.title,
                  }}
                >
                  <StyledFieldWrapper>
                    <StylesFieldLabel>Date Added</StylesFieldLabel>
                    <CustomFormItem name="createdAt" value={newNote.createdAt} type="date" width="100%" onChange={handleCustomFormItemChange} disabled />
                  </StyledFieldWrapper>
                  <StyledFieldWrapper>
                    <StylesFieldLabel>Follow up</StylesFieldLabel>
                    <CustomFormItem placeholder="E.g Will be show here" name="title" value={newNote.title} onChange={handleCustomFormItemChange} width="100%" />
                  </StyledFieldWrapper>
                </Form>
              </StyledRows>
              <StyledRows style={{ justifyContent: 'flex-end', marginTop: '40px' }}>
                <StyledCancelButton onClick={cancelNewItemInsert}>Cancel</StyledCancelButton>
                <StyledSaveButton
                  onClick={() => {
                    saveNote({ _id: '' })
                  }}
                >
                  Save
                </StyledSaveButton>
              </StyledRows>
            </StyledNoteWrapper>
          )}
          {notes.map((note: INote) => {
            return (
              <StyledNoteWrapper key={note._id}>
                <StyledActions
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  <StyledItemContainer>
                    <StyledCheckbox onChange={(e: CheckboxChangeEvent) => selectNote(e, note._id)} checked={note.selected} />
                    <StyledCheckboxMsg>Select</StyledCheckboxMsg>
                  </StyledItemContainer>
                  <StyledHeaderActionsWrapper>
                    {note.edit && <EditDeleteButton onClick={() => handleEditClick(note._id)} type="edit" value="Edit" />}
                    {note.delete && <EditDeleteButton onClick={() => handleDeleteModal(note._id)} type="delete" value="Delete" />}
                  </StyledHeaderActionsWrapper>
                </StyledActions>
                <StyledRows>
                  <CustomTextArea
                    label="Note"
                    placeholder="Note"
                    value={note.description}
                    name="description"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCustomTextAreaChangeForEdit(e, note._id)}
                    rows={7}
                    wrapperStyles={{ marginTop: 0 }}
                    disabled={!note.editable}
                  />
                  <Form
                    layout="vertical"
                    size="large"
                    name="basic"
                    style={{
                      alignSelf: 'flex-start',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '24px',
                    }}
                    initialValues={{
                      createdAt: note.createdAt,
                      title: note.title,
                    }}
                  >
                    <StyledFieldWrapper>
                      <StylesFieldLabel>Date Added</StylesFieldLabel>
                      <CustomFormItem placeholder="DD/MM/YYYY" name="createdAt" value={note.createdAt} type="date" width="100%" disabled />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper>
                      <StylesFieldLabel>Follow up</StylesFieldLabel>
                      <CustomFormItem
                        placeholder="E.g Will be show here"
                        name="title"
                        value={note.title}
                        width="100%"
                        disabled={!note.editable}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCustomFormItemChangeForEdit(e, note._id)}
                      />
                    </StyledFieldWrapper>
                  </Form>
                </StyledRows>
                {note.editable && (
                  <StyledRows style={{ justifyContent: 'flex-end', marginTop: '40px' }}>
                    <StyledCancelButton onClick={() => cancelEdit(note._id)}>Cancel</StyledCancelButton>
                    <StyledSaveButton
                      onClick={() => {
                        saveNote({ _id: note._id })
                      }}
                    >
                      Save
                    </StyledSaveButton>
                  </StyledRows>
                )}
              </StyledNoteWrapper>
            )
          })}
        </StyledWrapper>
      </StyledMinContainer>
    </StyledContainer>
  )
}

export default NotesContent
