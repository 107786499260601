// import Tabs from 'antd/lib/tabs'

// import DashboardTabContent from 'components/DashboardTabContent'
import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import ComingSoon from 'components/ComingSoon'

// import { StyledContainer, StyledFilterButton } from './Dashboard.styles'
// import DashboardFilterDrawer from 'drawers/DashboardFilterDrawer'
// import { useDashboard } from 'hooks'

// const { TabPane } = Tabs

const Dashboard = () => {
  // const { loading, onChange, open, closeDrawer, openDrawer, reportColumns, reportDataSource, detailsColumns, detailsDataSource, handleSubmit } = useDashboard()

  return (
    <Layout>
      <MainContentContainer pageName="Dashboard">
        <ComingSoon />
        {/* <StyledContainer>
          <Tabs
            defaultActiveKey="1"
            type="card"
            onChange={onChange}
            tabBarExtraContent={
              <StyledFilterButton onClick={openDrawer}>
                Filters
              </StyledFilterButton>
            }
          >
            <TabPane tab="WRITTEN BUSINESS" key="1">
              <DashboardTabContent
                reportColumns={reportColumns}
                reportDataSource={reportDataSource}
                detailsColumns={detailsColumns}
                detailsDataSource={detailsDataSource}
              />
            </TabPane>
            <TabPane tab="ON RISK" key="2">
              <DashboardTabContent
                reportColumns={reportColumns}
                reportDataSource={reportDataSource}
                detailsColumns={detailsColumns}
                detailsDataSource={detailsDataSource}
              />
            </TabPane>
            <TabPane tab="COMMISSION PAID" key="3">
              <DashboardTabContent
                reportColumns={reportColumns}
                reportDataSource={reportDataSource}
                detailsColumns={detailsColumns}
                detailsDataSource={detailsDataSource}
              />
            </TabPane>
            <TabPane tab="NTUS" key="4">
              <DashboardTabContent
                reportColumns={reportColumns}
                reportDataSource={reportDataSource}
                detailsColumns={detailsColumns}
                detailsDataSource={detailsDataSource}
              />
            </TabPane>
          </Tabs>
        </StyledContainer>
        <DashboardFilterDrawer
          open={open}
          closeDrawer={closeDrawer}
          loading={loading}
          handleSubmit={handleSubmit}
        /> */}
      </MainContentContainer>
    </Layout>
  )
}

export default Dashboard
