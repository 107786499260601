import * as _ from 'lodash'

import CustomDrawer from 'components/CustomDrawer'

import { StyledContainer } from './QuotesFilterDrawer.styles'
import { IQuotesFilterDrawer } from './QuotesFilterDrawer.types'
import CustomFormItem from 'components/CustomFormItem'

import { Form } from 'antd'
import useLifeTimeMortgagesTab from 'hooks/quotes/useLifeTimeMortgagesTab'
import { PoundIcon } from 'assets/images'

const QuotesFilterDrawer = ({ open, closeDrawer, handleSubmit, setFilterData, filterData }: IQuotesFilterDrawer) => {
  const { generateFilterOptions } = useLifeTimeMortgagesTab()
  const {
    providerOptions,
    initialSumRange,
    interestRateRange,
    rateTypeOptions,
    earlyRepaymentChargesPeriodRange,
    downsizingProtectionOptions,
    interestTypeOptions,
    ercOptions,
  } = generateFilterOptions()

  const handleCustomSelectChange = (name: string, value: string) => {
    setFilterData((prevState: any) => _.set(_.cloneDeep(prevState), name, value))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterData((prevState: any) => _.set(_.cloneDeep(prevState), e.target?.name, Number(e.target?.value)))
  }

  return (
    <CustomDrawer
      loading={false}
      open={open}
      closeDrawer={closeDrawer}
      title={'Filter Quotes'}
      disableSaveButton={false}
      handleSubmit={() => handleSubmit(filterData)}
    >
      <StyledContainer>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <CustomFormItem
            label="Providers"
            width={'100%'}
            placeholder="Providers"
            onChange={handleCustomSelectChange}
            options={providerOptions}
            maxTagCount={1}
            mode="multiple"
            name="providers"
            type="select"
          />
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <CustomFormItem
              label={`Initial Sum from £${initialSumRange.min}`}
              width={'100%'}
              placeholder="Start"
              onChange={handleAntdFormItemChange}
              name="initialSumRange.min"
              type="number"
              min={initialSumRange.min}
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label={`Initial Sum to £${initialSumRange.max}`}
              width={'100%'}
              placeholder="End"
              onChange={handleAntdFormItemChange}
              name="initialSumRange.max"
              type="number"
              max={initialSumRange.max}
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <CustomFormItem
              label={`Interest Rate From ${interestRateRange.min}%`}
              width={'100%'}
              placeholder="Start"
              onChange={handleAntdFormItemChange}
              name="interestRate.min"
              type="number"
              min={interestRateRange.min}
            />
            <CustomFormItem
              label={`Interest Rate To ${interestRateRange.max}%`}
              width={'100%'}
              placeholder="End"
              onChange={handleAntdFormItemChange}
              name="interestRate.max"
              type="number"
              max={interestRateRange.max}
            />
          </div>
          <CustomFormItem
            label="Rate Types"
            width={'100%'}
            placeholder="Rate Types"
            onChange={handleCustomSelectChange}
            options={rateTypeOptions}
            maxTagCount={1}
            mode="multiple"
            name="rateType"
            type="select"
          />
          <CustomFormItem
            label="Interest Type"
            width={'100%'}
            placeholder="Interest Type"
            onChange={handleCustomSelectChange}
            options={interestTypeOptions}
            maxTagCount={1}
            mode="multiple"
            name="interestType"
            type="select"
          />
          <CustomFormItem
            label="Early repayment charge type"
            width={'100%'}
            placeholder="erc..."
            onChange={handleCustomSelectChange}
            options={ercOptions}
            maxTagCount={1}
            mode="multiple"
            name="erc"
            type="select"
          />
          <CustomFormItem
            label={`Early repayment charges period range from ${earlyRepaymentChargesPeriodRange.min} to ${earlyRepaymentChargesPeriodRange.max}`}
            width={'100%'}
            placeholder="Early repayment charges period"
            onChange={handleAntdFormItemChange}
            name="earlyRepaymentChargesPeriod"
            type="number"
            min={earlyRepaymentChargesPeriodRange.min}
            max={earlyRepaymentChargesPeriodRange.max}
          />
          <CustomFormItem
            label="Downsizing protection"
            width={'100%'}
            placeholder="Downsizing protection"
            onChange={handleCustomSelectChange}
            options={downsizingProtectionOptions}
            maxTagCount={1}
            mode="multiple"
            name="downsizingProtection"
            type="select"
          />
        </Form>
      </StyledContainer>
    </CustomDrawer>
  )
}

export default QuotesFilterDrawer
