import { useEffect, useState } from 'react'
import { StyledContainer, StyledLabel, StyledOption, ValuesContainer, StyledRequiredError } from './CustomCheckbox.styles'
import { CustomCheckboxProps, TOption } from './CustomCheckbox.types'

const CustomCheckbox = ({ label, onChange, options, name, value, required = false }: CustomCheckboxProps) => {
  // Note: false is passed it needs this check to set it as default value instead of null
  const initialValue = value ? value : value === false ? false : null
  const [checkedValue, setCheckedValue] = useState(initialValue)
  const [error, setError] = useState(false)

  const handleChange = (value: string) => {
    if (checkedValue === value) {
      setCheckedValue(null)

      // Note: if current value becomes null, the error will be displayed
      if (required) {
        setError(true)
      } else {
        setError(false)
      }
    } else {
      if (error) setError(false)
      setCheckedValue(value)
    }
  }

  useEffect(() => {
    onChange({ name, value: checkedValue })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValue])

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <ValuesContainer>
        {options.map((option: TOption) => (
          <StyledOption
            key={option.label}
            bgColor={error ? '#E73F3F' : checkedValue === option.value ? '#38003c' : '#eaeaea'}
            color={error ? '#fefefe' : checkedValue === option.value ? '#fefefe' : '#120224'}
            onClick={handleChange.bind(this, option.value)}
          >
            {option.label}
          </StyledOption>
        ))}
      </ValuesContainer>
      {error && <StyledRequiredError>Required</StyledRequiredError>}
    </StyledContainer>
  )
}

export default CustomCheckbox
