import { useState, useEffect } from 'react'
import { Modal } from 'antd'

import { eventManager } from 'utils'

import { StyledLabelWrapper, StyledWrapper } from './ConfirmModal.styles'

const initialState = {
  isOpen: false,
  data: { header: '', desc: '' },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const ConfirmModal = () => {
  const [modalState, setModalState] = useState(initialState)

  useEffect(() => {
    eventManager.addListener('confirm-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeConfirmModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
  }

  return (
    <Modal
      title=""
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Yes"
      cancelText="No"
      closable={false}
      className="edit-asset-modal"
    >
      <StyledWrapper>
        <StyledLabelWrapper>{modalState.data?.header} </StyledLabelWrapper>
      </StyledWrapper>
    </Modal>
  )
}

export default ConfirmModal
