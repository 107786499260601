import { createContext } from 'react'

export interface IContextUser {
  _id: string
  avatar: string
  companyId: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  role: string
  isIressCredentialsVerified: boolean
  fcaNumber?: string
  __typename: string
}

export interface UserContext {
  user: IContextUser
  refetch: any
  loading: boolean
  error: any
}
const context: UserContext = {
  user: {
    _id: '',
    avatar: '',
    companyId: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    role: '',
    isIressCredentialsVerified: false,
    fcaNumber: '',
    __typename: '',
  },
  refetch: () => null,
  loading: false,
  error: {},
}

export default createContext(context)
