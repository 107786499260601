import * as uuid from 'uuid'

import { ProviderAviva, ProviderCanadaLife, ProviderJust, ProviderLV, ProviderLandG, ProviderM2L, ProviderPure, ProviderSLHF } from 'assets/images'

/***
 * this function compares two strings equality with case sensitive
 * @param {string} str1
 * @param {string} str2
 */
const compareStringEquality = (str1: string, str2: string) => {
  return str1 === str2
}

export const hasLowerCase = (str: string): boolean => {
  return /[a-z]/.test(str)
}
export const hasUpperCase = (str: string): boolean => {
  return /[A-Z]/.test(str)
}
export const hasDigit = (str: string): boolean => {
  return /\d/.test(str)
}
export const hasSpecial = (str: string): boolean => {
  return /[^0-9a-zA-Z]/.test(str)
}

export const stringMinLength = (str: string, minLength: number): boolean => {
  if (str.length < minLength) {
    return false
  }
  return true
}

export const isValidPassword = (password: string) => {
  if (hasLowerCase(password) && hasUpperCase(password) && hasDigit(password) && hasSpecial(password) && stringMinLength(password, 10)) {
    return true
  }

  return false
}

/**
 * this function generates a random number
 * @param length returned numbers character count
 * @returns returns a random number, which's character count is equal to "length"
 */
const generateIdNumber = (length = 8) => {
  const hexUuid = uuid.v4().replace(/-/g, '')
  const hexNumber = hexUuid.substring(0, length)
  return Number(parseInt(hexNumber, 16).toString().substring(0, length))
}

/**
 * Create a new object with all properties of the input object except "__typename". (it removes "__typename" key recursively)
 *
 * @param obj - The input object to filter.
 * @returns A new object with all properties of the input object except "__typename".
 */
const removeTypeNameFromObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypeNameFromObject(item))
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: any = {}
    Object.keys(obj).forEach((key) => {
      if (key !== '__typename') {
        newObj[key] = removeTypeNameFromObject(obj[key])
      }
    })
    return newObj
  }
  return obj
}

const calculateAge = (birthdate: string) => {
  if (!birthdate) return null
  const [year, month, day] = birthdate.split('-')
  const birthdateObj = new Date(Number(year), Number(month) - 1, Number(day))
  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthdateObj.getFullYear()

  const isBeforeBirthdateAnniversary =
    currentDate.getMonth() < birthdateObj.getMonth() || (currentDate.getMonth() === birthdateObj.getMonth() && currentDate.getDate() < birthdateObj.getDate())

  if (isBeforeBirthdateAnniversary) {
    age--
  }

  return age
}

const getAvatarFromProviderName = (providerName: string) => {
  switch (providerName) {
    case 'Legal & General':
      return ProviderLandG

    case 'LV=':
      return ProviderLV

    case 'Pure Retirement':
      return ProviderPure

    case 'Canada Life':
      return ProviderCanadaLife

    case 'Aviva':
      return ProviderAviva

    case 'more2life':
      return ProviderM2L

    case 'JUST':
      return ProviderJust

    // Note: we don't know how this provider name is returned and we will update when we know it
    case '':
      return ProviderSLHF

    default:
      return ''
  }
}

const formatNumberWithCommas = (number: number) => {
  if (number !== 0 && !number) return ''
  // Convert the number to a string and split it into integer and decimal parts
  var parts = number.toString().split('.')

  // Add commas to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Join the integer and decimal parts back together
  var formattedNumber = parts.join('.')

  return formattedNumber
}

const parseValue = (value: any) => {
  if (typeof value === 'string') {
    if (value === 'true') {
      return true
    } else if (value === 'false') {
      return false
    } else if (!isNaN(Number(value))) {
      return Number(value)
    }
  }
  return value
}

const resetFormData = (data: any) => {
  const resetObject = (obj: any) => {
    return Object.keys(obj).reduce((acc: any, key) => {
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        acc[key] = resetObject(obj[key])
      } else {
        acc[key] = null
      }
      return acc
    }, {})
  }

  return resetObject(data)
}

type FlattenedObject = {
  [key: string]: any
}

const flattenObject = (obj: any, prefix: string = ''): FlattenedObject => {
  return Object.keys(obj).reduce((acc: FlattenedObject, k: string): FlattenedObject => {
    const pre: string = prefix.length ? `${prefix}.` : ''
    if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  flattenObject,
  generateIdNumber,
  compareStringEquality,
  isValidPassword,
  removeTypeNameFromObject,
  calculateAge,
  getAvatarFromProviderName,
  formatNumberWithCommas,
  parseValue,
  resetFormData,
}
