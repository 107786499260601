import React from 'react'
import Select from 'antd/lib/select'

import { TCustomSelect } from './CustomSelect.types'
import { StyledWrapper, StyledLabel, StyledSelect } from './CustomSelect.styles'

const { Option } = Select
const CustomSelect = ({
  placeholder,
  defaultValue,
  onChange,
  options,
  width,
  height,
  disabled,
  label,
  allowClear = true,
  maxTagCount = 1,
  mode,
  name,
}: TCustomSelect) => {
  return (
    <StyledWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        disabled={disabled}
        width={width}
        height={height}
        size="large"
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange.bind(this, name)}
        allowClear={allowClear}
        maxTagCount={maxTagCount}
        mode={mode}
        showSearch={true}
      >
        {options.map((option: any) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </StyledWrapper>
  )
}

export default CustomSelect
