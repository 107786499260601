import { useEffect, useState } from 'react'
import { StyledContainer, StyledLabel, StyledOption, ValuesContainer, StyledDescription } from './CustomWarnings.styles'
import { CustomWarningsProps } from './CustomWarnings.types'

const CustomWarnings = ({ label, onChange, options, name, value, required = false }: CustomWarningsProps) => {
  // Note: false is passed it needs this check to set it as default value instead of null
  const initialValue = value ? value : value === false ? false : null
  const [checkedValue, setCheckedValue] = useState(initialValue)
  const [error, setError] = useState(false)
  const optionsCount = options.length
  const description = options.find((option: any) => option.value === checkedValue)?.description
  const lineColor = options.find((option: any) => option.value === checkedValue)?.bgColor || '#eceff1'

  const handleChange = (value: string) => {
    if (checkedValue === value) {
      setCheckedValue(null)

      // Note: if current value becomes null, the error will be displayed
      if (required) {
        setError(true)
      } else {
        setError(false)
      }
    } else {
      if (error) setError(false)
      setCheckedValue(value)
    }
  }

  useEffect(() => {
    onChange({ name, value: checkedValue })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValue])

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <ValuesContainer optionsCount={optionsCount}>
        {options.map((option: any) => (
          <StyledOption
            key={option.label}
            bgColor={option.value === checkedValue ? option.bgColor : option.initialBgColor}
            color={'#fefefe'}
            onClick={handleChange.bind(this, option.value)}
          >
            {option.label}
          </StyledOption>
        ))}
      </ValuesContainer>
      {description && <StyledDescription lineColor={lineColor}>{description}</StyledDescription>}
    </StyledContainer>
  )
}

export default CustomWarnings
