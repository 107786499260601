import { loader } from 'graphql.macro'

// Note: Mutations
export const signInMutation = loader('./signInMutation.graphql')
export const signUpMutation = loader('./signUpMutation.graphql')
export const forgetPasswordMutation = loader('./forgetPasswordMutation.graphql')
export const resetPasswordWithTokenMutation = loader('./resetPasswordWithTokenMutation.graphql')
export const suspendUsersMutation = loader('./suspendUsersMutation.graphql')
export const resetPasswordMutation = loader('./resetPasswordMutation.graphql')
export const resendWelcomeEmailMutation = loader('./resendWelcomeEmailMutation.graphql')
export const addUserMutation = loader('./addUserMutation.graphql')
export const editUserMutation = loader('./editUserMutation.graphql')
export const editUserIressDetailsMutation = loader('./editUserIressDetailsMutation.graphql')
export const editUserIressDetailsMutationFromModal = loader('./editUserIressDetailsMutationFromModal.graphql')
export const changeAvatarMutation = loader('./changeAvatarMutation.graphql')
export const changePasswordMutation = loader('./changePasswordMutation.graphql')
export const deleteUsersMutation = loader('./deleteUsersMutation.graphql')
export const saveNoteMutation = loader('./saveNoteMutation.graphql')
export const deleteUserNotesMutation = loader('./deleteUserNotesMutation.graphql')
export const deleteUserFilesMutation = loader('./deleteUserFilesMutation.graphql')

// Note: Queries
export const userQuery = loader('./userQuery.graphql')
export const userIressQuery = loader('./userIressQuery.graphql')
export const usersQueryForUsersList = loader('./getUsersQuery.graphql')
export const getUserByIdQuery = loader('./getUserByIdQuery.graphql')
export const getUserNotesQuery = loader('./getUserNotesQuery.graphql')
export const getUserFilesQuery = loader('./getUserFilesQuery.graphql')
